import React from "react";
import './TextCard.css';

function TextCard ({ title, children }) {
    return (
        <div className="text-card">
            <h2 className="card-title">{title}</h2>
            <div>{children}</div>
        </div>
    );
}

export default TextCard;