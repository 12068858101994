import React, { useState, useRef } from 'react';
import axios from 'axios';
import './UploadData.css';

function UploadData() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordCount, setRecordCount] = useState(0);

  const fileInputRef = useRef(null);
  const recordCountIntervalRef = useRef(null);
  const processingStatusIntervalRef = useRef(null);

  // Clear record count interval when processing completes
  const clearRecordCountInterval = () => {
    if (recordCountIntervalRef.current) {
      clearInterval(recordCountIntervalRef.current);
      recordCountIntervalRef.current = null;
    }
  };

  // Clear status interval when processing completes
  const clearProcessingStatusInterval = () => {
    if (processingStatusIntervalRef.current) {
      clearInterval(processingStatusIntervalRef.current);
      processingStatusIntervalRef.current = null;
    }
  }

  // Handle click to select file
  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (validateFile(selectedFile)) {
      setFile(selectedFile);
    }
  };

  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (validateFile(droppedFile)) {
      setFile(droppedFile);
    }
  };

  // Validate .zip file
  const validateFile = (file) => {
    const validExtensions = ['application/zip'];
    if (!validExtensions.includes(file.type)) {
      setErrorMessage('Please upload a valid .zip file.');
      return false;
    }
    return true;
  };

  // Prevent default behavior for drag over events
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Trigger file input when dropzone clicked
  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  }

  // keep record count
  const monitorRecordCount = (token) => {
    recordCountIntervalRef.current = setInterval(async () => {
      try {
        const response = await axios.get('https://spoti-pals-1950205a25ce.herokuapp.com/api/record-count', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true
        });

        const count = response.data.count;
        console.log('Current record count:', count);
        setRecordCount(count);
      } catch (error) {
        console.error('Error checking record count:', error);
        clearRecordCountInterval();
      }
    }, 2000);
  };

  // Poll the backend to check processing status
  const monitorProcessingStatus = (token) => {
    processingStatusIntervalRef.current = setInterval(async () => {
      try {
        const response = await axios.get('https://spoti-pals-1950205a25ce.herokuapp.com/api/status', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true
        });

        const status = response.data.status;
        console.log('Processing status:', status);

        if (status === 'complete') {
          console.log('Error occurred here: Status === complete! -J')
          setStatusMessage('Processing complete.');
          setIsProcessing(false);
          clearProcessingStatusInterval();
          clearRecordCountInterval();
        } else if (status === 'error') {
          console.log('Error occurred here: Status === error! -J')
          setErrorMessage('Error occurred during processing.');
          setIsProcessing(false); 
          clearProcessingStatusInterval();
          clearRecordCountInterval();
        }
      } catch (error) {
        console.error('Error checking status: J', error);
        setErrorMessage('Error checking processing status.');
        setIsProcessing(false);
        clearProcessingStatusInterval();
      }
    }, 5000); // Poll every 2 seconds until clearInterval
  };

  // upload File
  const uploadFile = async () => {
    if (!file) return;

    setIsLoading(true);
    setStatusMessage('');
    setErrorMessage('')
    setRecordCount(0);

    const formData = new FormData();
    formData.append('file', file);

    // Log formData entries
    for (let pair of formData.entries()) {
      console.log(`formData. J: ${pair[0]}:`, pair[1]);
    }

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://spoti-pals-1950205a25ce.herokuapp.com/api/upload', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });

      setStatusMessage('File uploaded succesfully! Processing will continue in the background.');
      setIsProcessing(true);
      setIsLoading(false);
      monitorRecordCount(token);
      monitorProcessingStatus(token); // Check processing status
      console.log('Server response. J:', response.data.message);
    } catch (error) {
      console.error('Error uploading file: J', error);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
        setErrorMessage('Error uploading file. Please try again.');
        setIsLoading(false);
      }
      setErrorMessage('Error uploading file. Please try again. J' );
    } 
  };

  return (
    <div className="upload-container">
      {/* Dropzone for file */}
      <div
        className={`dropzone ${isLoading ? 'disabled' : ''}`} // Disable dropzone while loading
        onClick={handleDropzoneClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={isLoading ? { pointerEvents: 'none', opacity: 0.5 } : {} } // Disable while loading
      >
        <p>Drag and drop, or click to select a .zip file.</p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept=".zip"
          className="file-input"
          disabled={isLoading}
        />
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {/*statusMessage && <p className="status-message">{statusMessage}</p>*/}

      {/* Info on the file and processing status */}
      {file && (
        <div className="file-details">
          <p><strong>Selected file:</strong> {file.name}</p>
          <p><strong>File size:</strong> {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
          <p><strong>Status:</strong> {statusMessage}</p>
          <p><strong>Current record count:</strong> {recordCount}</p>
          <button 
            onClick={uploadFile}
            disabled={isLoading || isProcessing}>
            {isLoading ? 'Uploading...' : isProcessing ? 'Processing...' : 'Upload File'}
          </button>
        </div>
      )}

      {/* Show loading icon */}
      {isLoading && (
        <div className='loading'>
          <p>Processing your file, please wait...</p>
          <div className='loading-icon'></div>
        </div>
      )}
    </div>
  );
}

export default UploadData;
