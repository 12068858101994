import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Layout from './components/Layout';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import StatHistory from './pages/StatHistory';
import WeeklyStat from './pages/WeeklyStat';
import FriendPage from './pages/FriendPage';
import Profile from './pages/Profile';
import PublicProfile from './pages/PublicProfile';
import UploadData from './pages/UploadData';
import Stats from './pages/Stats';

function App() {

  console.log('API URL:', process.env.REACT_APP_API_URL);

  return (
    <Router>
      <div className="App">
        {/*<Header /> {/* The header of the website */}
        <Layout>
        {/*<div className="content-container">*/}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/stat-history" element={<StatHistory />} />
            <Route path="/weekly-stat" element={<WeeklyStat />} />
            <Route path="/friend/:name" element={<FriendPage />} /> {/* Dynamic Route */}
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/public-profile/:username" element={<PublicProfile />} />
            <Route path="/upload-data" element={<UploadData />} />
            <Route path="/stats" element={<Stats />} />
          </Routes>
         {/*</div>*/}
        </Layout>

      </div>

      
    </Router>
  );
}

export default App;
