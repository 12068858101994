import React, { useEffect, useState } from 'react';
import UserPill from '../components/UserPill';
import Section from '../components/Section';
import TextCard from '../components/TextCard';
import Button from '../components/Button';
import axios from 'axios';
import './Design.css';
import './Home.css';
import '../components/TextCard.css';

function Home() {
  // User profile
  const [users, setUsers] = useState([]);
  const [statistics, setStatistics] = useState({ totalUsers: null, 
    totalEntries: null});

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get('https://spoti-pals-1950205a25ce.herokuapp.com/api/statistics/common-stats');
        setStatistics(response.data);
      } catch (err) {
        console.error('Error fetching statistics:', err);
      }
    };

    fetchStatistics();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://spoti-pals-1950205a25ce.herokuapp.com/api/users');
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUserData();
  }, []);

  const checkProcessingStatus = async () => {
    try {
      const response = await axios.get('https://spoti-pals-1950205a25ce.herokuapp.com/api/status', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true,
      });

      const status = response.data.status;
      console.log('Checked status in Home Page:', status);


      if (status === 'complete') {
        window.location.href = '/stats';
      }

    } catch (err) {
      console.error('Error checking status:', err);
      window.location.href = '/upload-data';
    }
  };

  return (
    <div className="home">
      {/* Sections */}
      <Section backgroundColor={"white"}>
        <TextCard title="What does your music say about you?">
          <Button label="Find out"/>
          <Button label="Upload your data" onClick={checkProcessingStatus}/>
        </TextCard>
        <TextCard title="Discover:">
          {statistics.totalUsers !== null && statistics.totalEntries !== null ? (
            <div>
              <p><span className="card-content-bold">{statistics.totalUsers}</span> <span className="card-content"> Users</span> </p>
              <p><span className="card-content-bold">{statistics.totalEntries}</span> <span className="card-content"> Streams</span></p>
              <p><span className="card-content-bold">{statistics.uniqueRecords}</span> <span className="card-content"> Unique records</span></p>
            </div>
          ) : (
            <p>Loading statistics ...</p>
          )}
        </TextCard>
      </Section>

      <Section backgroundColor={"gray"}>
        <TextCard title="Analyze your music listening patterns">
          <p>Analyze your music listening patterns</p>
        </TextCard>
        <TextCard title="Card Two - Section 2">
          <p>Content for Card Two in Section 2</p>
        </TextCard>
      </Section>

      {/* Registered Users Section */}
      {users.length > 0 && (
      <div className="users-section">
        <h2 className="section-title">Make Some Friends!</h2>
        <div className="users-container">
          {users.map(user => (
            <UserPill key={user.username} username={user.username} />
          ))}
        </div>
      </div>
      )}

  </div>
  );
}

export default Home;
