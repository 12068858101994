import React from 'react';

function Stats() {
    return (
        <div>
            <h2>Stats</h2>
            <p>Where you can see all of your stats so far. Maybe if you pay :)</p>
        </div>
    );
}

export default Stats;