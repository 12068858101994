// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

function Profile() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://spoti-pals-1950205a25ce.herokuapp.com/api/profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          setError('Failed to load profile');
        }
      } catch (error) {
        setError('Error loading profile');
      }
    };

    fetchUserData();
    
    // Placeholder for fetching stats
    const fetchUserStats = async () => {
      // Simulate fetching stats
      const placeholderStats = {
        totalSongsPlayed: 1234,
        favoriteArtist: 'Unknown Artist',
        favoriteSong: 'Unknown Song',
      };
      setStats(placeholderStats);
    };

    fetchUserStats();
  }, []);

  const handleUploadData = () => {
    // Handle data upload logic here
    console.log('Upload data button clicked');
    navigate('/upload-data');
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user || !stats) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="profile-container">
      {/* Profile Information Box */}
      <div className="profile-card">
        <h1 className="profile-title">Profile</h1>
        <p className="profile-item"><strong>Username:</strong> {user.username}</p>
        <p className="profile-item"><strong>Email:</strong> {user.email}</p>
        <p className="profile-item"><strong>First Name:</strong> {user.first_name}</p>
        <p className="profile-item"><strong>Last Name:</strong> {user.last_name}</p>
      </div>

      {/* Streaming Information Box */}
      <div className = "profile-card">
        <h2 className="profile-title"> Your Stats' Status</h2>
        <p className="profile-item"><strong>Total Songs Played:</strong> {stats.totalSongsPlayed}</p>
        <p className="profile-item"><strong>Favorite Artist:</strong> {stats.favoriteArtist}</p>
        <p className="profile-item"><strong>Favorite Song:</strong> {stats.favoriteSong}</p>
        
        {/* Upload data button */}
        <div className="upload-button-container">
          <button className="upload-button" onClick={handleUploadData}>
            Upload Streaming Data
          </button>
        </div>
      </div>


    </div>
  );
}

export default Profile;