import React from "react";
import './Section.css';

function Section({ children, backgroundColor }) {
    return (
        <div className="section" style={{ backgroundColor: backgroundColor }}>
            {children}
        </div>
    );
}

export default Section;