import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaCog } from 'react-icons/fa'; // Settings icon
import { FaBars } from 'react-icons/fa'; // Hamburger icon
import Sidebar from './Sidebar';
import './Header.css';

function Header() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
        setIsSidebarOpen(false);
      } else {
        setIsMobileView(false);
        setIsSidebarOpen(false); // Close sidebar when resizing to desktop view
      }
    };

    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
        setDropdownVisible(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <h1>
        <Link to="/" className="title-link">SpotiPals</Link>
      </h1>

      <nav className="nav-links-right">
        {isMobileView ? (
          <div className="settings-container">
            <FaCog className="settings-icon" onClick={toggleDropdown} />
            {dropdownVisible && (
              <div className="dropdown-menu">
                <Link to="/register" className="auth-link">Register</Link>
                <Link to="/login" className="auth-link">Login</Link>
                <Link to="/profile" className="profile-link">Profile</Link>
              </div>
            )}
          </div>
        ) : (
          <div className="nav-items">
            <Link to="/register" className="auth-link">Register</Link>
            <Link to="/login" className="auth-link">Login</Link>
            <Link to="/profile" className="auth-link">Profile</Link>
          </div>
        )}
      </nav>

      {/* Hamburger Icon, conditionally rendered */}
      <nav className="nav-links-left">
        {isMobileView && !isSidebarOpen ? (
            <div onClick={toggleSidebar} className="hamburger-container">
                <FaBars className="hamburger" />
            </div>
        ) : (
            <div className="nav-items">
                <Link to="/" className="auth-link">Home</Link>
                <Link to="/about" className="auth-link">About</Link>
                {/*<Link to="/stat-history" className="auth-link">Stat History</Link>
                <Link to="/weekly-stat" className="auth-link">Weekly Stat</Link>*/}
            </div>
        )}
      </nav>

      {/* Sidebar container with conditional class for visibility */}
      <div 
        ref={sidebarRef}
        className={`sidebar-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <Sidebar />
      </div>
    </header>
  );
}

export default Header;
