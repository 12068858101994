// Button.js
import React from "react";
import { Link } from 'react-router-dom';
import './Button.css';

function Button({ label, to, onClick }) {
    return (
        to ? (
            <Link to={to} className="button">
                {label}
            </Link>
        ) : (
            <button onClick={onClick} className="button">
                {label}
            </button>
        )
    );
}

export default Button;